





























































import router from '@/router';
import { Vue } from 'vue-property-decorator';
import Component from '@/routeComponent'
import store from '@/store';
import WalletBar from '@/components/walletBar.vue';
import CircleCard from '@/components/circleCard.vue';
import { StorageServices } from '@/services/StorageServices'
import CallToButton from '@/components/callToButton.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import SwiperSlides from '@/components/swiperSlides.vue';
import PeriodiCard from '@/components/periodiCard.vue';
import { ModalServices } from '@/services/ModalServices';
import BuyAssetModal from '@/components/buyAssetModal.vue';
import AssetTypeIcon from '@/components/assetTypeIcon.vue';
import { AssetClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        WalletBar,
        CallToButton,
        SwiperSlides,
        PeriodiCard,
        AssetTypeIcon
    }
})
export default class AssetsListSwitch extends Vue {

    assets: OM.AssetForBuyListVm[] = [];
    benchmark: OM.AssetForBuyListVm = null;

    beforeRouteEnter(to, from, next){
        let filterVm = new OM.AssetFilterVm();
        filterVm.assetType = to.params.assetType;
        filterVm.macroClass = to.params.macroClass;
        filterVm.microClass = to.params.microClass;
        AssetClient.getAssetsForBuyList(filterVm)
        .then( data => {
            next((vc: AssetsListSwitch) => {
                let benchIndex = data.findIndex(x => x.tipoAsset.toLowerCase() == 'indice');
                if(benchIndex > -1)
                    vc.benchmark = data.splice(benchIndex, 1)[0];
                vc.assets = data;
            });
        })
    }

    openBuySellAssetModal(asset: VM.AssetForBuyListVm) {
        ModalServices.OpenModal(BuyAssetModal,
        {
            asset: asset,
            portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
            creaSwitchVm: store.state.creaSwitchVm,
            saveOrderCb: (vm: OM.CreaOrdineVm) => {
                store.state.creaSwitchVm.ordiniAcquisto.push(vm);
                StorageServices.setCreaSwitch(store.state.creaSwitchVm);
                
                PortafoglioClient.getPortafoglioStatusWithSwitch({
                    portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
                    creaSwitchVm: store.state.creaSwitchVm
                })
                .then(x => {
                    store.state.portafoglioStatus = x;
                    StorageServices.setPortafoglioStatus(x);
                });
                
                ModalServices.CloseLastModal();
                this.$router.go(-4);
            }
        });
    }

}

